:root{
    --dark-color:#202124;
}

.empresas-registro{
    display: flex;
    min-height: 100vh;
    width: 100vw;
    max-width: 100%;
    background: #F5EBEC;
    padding: 15px;
}
.empresas-container{
    max-width: 640px;
    width: 100%;
    margin: auto;
}
.empresas-container .card{
    width: 100%;
    border: 1px solid #dadce0;
    background: #FFF;
    box-shadow: none;
    margin-top: 20px;
    border-radius: 8px;
}
.card.card-image-bg{
    padding: 0;
    height: 160px;
    background-image: url('../img/09_house_party.jpg');
    background-position: center;
    background-size: 100% auto;
}
.text-danger{
    color: #d93025;
}
.card.card-border-top{
    border-top: 10px solid #8c1f40;
}
.card h5{
    margin-top: 0;
}

label{
    font-size: 15px;
    display: block;
    padding-bottom: 8px;
    margin-top: 10px;
}
.input-field{
    margin-bottom: 0!important;
}
input, select{
    color: var(--dark-color);
}
input.browser-default{
    padding: 12px 12px;
    color: var(--dark-color);
    font-size: 17px;
    border: 1px solid rgb(234 234 234);
    width: 100%;
    border-radius: 4px;
}
input.browser-default:focus{
    outline: none;
}
.checkbox_content {
    max-height: 150px;
    overflow-y: auto;
    border: 1px solid rgb(234 234 234);
}
.checkbox_content p{
    border-top: 1px solid rgb(234 234 234);
    padding-left: 10px;
}
.checkbox_content input[type="checkbox"]{
    position: initial!important;
    opacity: 1!important;
    margin-right: 10px;
    height: 20px;
    width: 20px;
}
.checkbox_content p label{
    display: flex;
    align-items: center;
}