._404_container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
}
._404_content{
    max-width: 400px;
    background: #FFF;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
}
._404_content p{
    margin-bottom: 20px;
}