:root{
    --primary-color: #5279BB;
}

*{
    margin: 0;
    padding: 0;
}
body{
    font-family: 'Inter', sans-serif!important;
    font-weight: 500;
    color: #333;
    background: #F5F7FA;
}
h1,h2,h3,h4,h5,h6{
    font-weight: bold!important;
}
strong{
    font-weight: 600;
}
label{
    color: #696868;
}
.navbar{
    background: var(--primary-color);
}
.brand-logo{
    padding-left: 15px!important;
    font-size: 1.5rem!important;
    font-weight: bold;
}
.card-image img{
    height: 150px;
}
.login_card_content{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}
.login_card{
    margin: auto;
}
.login_card .card{
    max-width: 500px;
    margin: auto;
}
.containerx{
    max-width: 1200px;
    margin: auto;
    overflow-x: auto;
    padding: 10px;
}
/* home */

.table tr{
    border-bottom: 10px solid #f2f6f7;
}
.table td{
    background: #FFF;
}
.table a{
    display: block;
}
.table .material-icons{
    margin-right: 8px;
    border-radius: 50%;
    padding: 2px;
    color: #FFF;
}

.table .done{
    background-color: var(--primary-color);
}
.table .access_alarm{
    background-color: orange;
}
.table tr td:first-child>div{
    display: flex;
    align-items: center;
}
a.contact_link{
    font-weight: bold;
    color: var(--primary-color);
}
/* Details */

.contact_content{
    background: #FFF;
    padding: 10px;
    margin-top: 30px;
}
.contact_content input, .contact_content textarea{
    border: 1px solid #e5e7eb!important;
    box-sizing: border-box!important;
    border-radius: 4px!important;
    padding: 23px 15px!important;
    background: #FFF!important;
    color: #333;
    font-weight:500;
}
.contact_content input:focus{
    border-color: rgba(59, 130, 246, 0.5)!important;
}
.contact_content .col{
    padding-top: 20px!important;
}
.contact_content span{
    display: block;
    padding: 5px 0px;
}
.contact_content .name .avatar{
    width: 60px;
    background: tomato;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    border-radius: 50%;
    margin-right: 10px;
    color: #FFF;
    font-size: 30px;
}
.contact_content .name{
    display: flex;
    align-items: center;
}
.name h4{
    margin: 0;
}
.photos_container{
    display: flex;
}
.contact_content hr{
    border-color: cornflowerblue;
    opacity: 0.2;
}
.contact_photo{
    max-width: 130px;
    padding-right: 8px;
}
.contact_photo img{
    width: 100%;
}
/*  */

.mr-2{
    margin-right: 10px;
}
.attended{
    padding-top: 10px;
}
.input_file{
    width: 100%;
}
.back svg{
    width: 30px;
    margin-right: 10px;
}
.back{
    font-size: 1.7rem;
    display: flex;
    align-items: center;
    color: #333;
}

.btn.grey{
    color: #333!important;
    margin-right: 10px!important;
}

.align-right{
    text-align: right;
}

.action_buttons{
    max-width: 150px;
    width: 150px;
}

.sure{
    font-size: 20px;
}

.td_buttons{
    display: flex;
}


@media(max-width:432px){
    .mr-2{
        margin-bottom: 5px!important;
    }
}

@media(min-width:600px ) and (max-width:1187px){
    .mr-2{
        margin-bottom: 5px!important;
    }
}

.btn-floating{
    position: fixed;
    bottom: 20px;
    right: 20px;
}
select{
    display: block;
}
.text-right{
    text-align: right;
}
.delete_icon{
    color: #d50000!important;
    cursor: pointer;
}